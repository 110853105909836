import { getCompanyShow } from '@/api/company/company'


let module = {
  namespaced: true,
  state: {
    company_info: {},//公司基本信息存储
    company_fengyun: {},//公司风云人物
    company_xiangce: {},//公司相册

  },
  mutations: {
    modify(state, payload) {
      console.log('vuex', payload)
      state.company_info = payload.company_info
      console.log('companyInfo', state.company_info)
    },
    fengyun(state, payload) {
      console.log('vuex', payload)
      state.company_fengyun = payload.company_fengyun
      console.log('company_fengyun', state.company_fengyun)
    },
  }
  ,
  actions: {
  }
}
export default module