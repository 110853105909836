import { connect } from "echarts";
//精英版页面路由
const eliteRouter = [

  //招聘工具
  {
    path: "/",
    component: () => import("@/elitePages/elite_layout/index.vue"),
    redirect: '/elite_index',
    children: [
      {
        path: "/elite_index",//首页
        name: "elite_index",
        component: () => import("@/elitePages/elite_index/index.vue"),
        meta: {
          parentUrl: '/elite_index',
          token: '/'
        }
      },
      {
        path: "/elite_position",//职位
        name: "elite_position",
        component: () => import("@/elitePages/elite_position/index.vue"),
        meta: {
          parentUrl: '/elite_position',
          token: '/'
        }
      },
      {
        path: "/elite_position_details",//职位
        name: "elite_position_details",
        component: () => import("@/elitePages/elite_position/compontent/jd_details"),
        meta: {
          parentUrl: '/elite_position',
          token: '/'
        }
      },
      {
        path: "/odd_jd_elite_position_details",// 零工职位
        name: "odd_jd_elite_position_details",
        component: () => import("@/elitePages/elite_position/compontent/odd_jd_elite_position_details"),
        meta: {
          parentUrl: '/elite_position',
          token: '/'
        }
      },
      {
        path: "/elite_company",//公司
        name: "elite_company",
        component: () => import("@/elitePages/elite_company/index.vue"),
        meta: {
          parentUrl: '/elite_company',
          token: '/'
        }
      },
      {
        path: "/elite_company_details",//职位
        name: "elite_company_details",
        component: () => import("@/elitePages/elite_company/compontent/company_item"),
        meta: {
          parentUrl: '/elite_company',
          token: '/'
        }
      },
      {
        path: "/odd_jd_company_item",// 零工企业
        name: "odd_jd_company_item",
        component: () => import("@/elitePages/elite_company/compontent/odd_jd_company_item"),
        meta: {
          parentUrl: '/elite_company',
          token: '/'
        }
      },
      {
        path: "/elite_video",//企业视频
        name: "elite_video",
        component: () => import("@/elitePages/elite_video/index.vue"),
        meta: {
          parentUrl: '/elite_video',
          token: '/',
          keepAlive:true
        }
      },
      {
        path: "/elite_resume",//简历
        name: "elite_resume",
        component: () => import("@/elitePages/elite_resume/index.vue"),
        meta: {
          parentUrl: '/elite_resume',
          token: '/'
        }
      },
      {
        path: "/elite_chat",//聊天
        name: "elite_chat",
        component: () => import("@/elitePages/elite_chat/index.vue"),
        meta: {
          parentUrl: '/elite_chat',
          token: '/' //有token时控制页面的权限
        }
      },
      {
        path: "/elite_me",
        name: "elite_me",
        component: () => import("@/elitePages/elite_me/index.vue"),
        meta: {
          parentUrl: '/elite_me',
          token: '/' //有token时控制页面的权限
        }
      },
      {
        path: "/feedback_elite",
        name: "feedback_elite",
        component: () => import("@/elitePages/feedback_elite/index.vue"),
        meta: {
          parentUrl: '/feedback_elite',
          token: '/' //有token时控制页面的权限
        }
      },

    ]
  }

]
export default eliteRouter