import {
    getCompanyShow
} from '@/api/company/company'


let module = {
    namespaced: true,
    state: {
        is_qiuzhi: false,
        dialogVisible: false,
        career_progress: 0
    },
    mutations: {
        qiuzhiChange(state, paylod) {
            console.log("qiuzhiasc", typeof paylod, paylod)
            if (typeof paylod == 'boolean') {
                state.is_qiuzhi = paylod
            } else {
                console.log("fullPath: ", paylod)
                state.is_qiuzhi = paylod.is_qiuzhi
                state.career_progress = paylod.career_progress
            }

        },
        resumeChange(state, paylod) {
            console.log("qiuzhiasc", paylod)
            state.dialogVisible = paylod
        },
    },
    actions: {}
}
export default module