<template>
  <div id="app">
    <!-- <img alt="Vue logo" src="./assets/logo.png"> -->
    <!-- <HelloWorld msg="Welcome to Your Vue.js App"/> -->
    <!-- <keep-alive v-if="$route.meta.keepAlive"> -->
      <router-view></router-view>
    <!-- </keep-alive> -->
    <componentsElite v-if="elite_login"></componentsElite>
  </div>
</template>

<script>
import componentsElite from "./components/components_elite/components_elite";
import { mapState } from "vuex";

export default {
  name: "App",
  components: {
    componentsElite,
  },
  data() {
    return {};
  },
  methods: {},
  watch: {
    $route(val, old) {
      let has_resume = sessionStorage.getItem("has_resume") || 0;
      let is_elite = sessionStorage.getItem("is_elite") || 0;

      if (is_elite) {
        sessionStorage.setItem(
          "oldPath",
          JSON.stringify({
            path: old.path,
            query: old.query,
          })
        );
      }
    },
  },
  computed: {
    ...mapState({
      elite_login: (state) => state.eliteLOgin.is_show,
    }),
  },
  mounted() {
    // this.$elite_login = true;
  },
  beforeDestroy() {},
};
</script>

<style>
@import "./assets/css/public.css";
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
} */
</style>
