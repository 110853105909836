import {
  getCompanyShow
} from '@/api/company/company'
import {
  work_time,
  educationBackground
} from './../../utils/searchtype'
import {
  remind_leave
} from '../../api/me/index'
import {
  lieLogin,
  polling
} from "@/api/login.js"

let module = {
  namespaced: true,
  state: {
    company_data: {},
    jd_data: [],
    context: 0,
    approve_count: 0,
    ask_count: 0,
    account_count: 0,
    is_unread: '',
    resume_management_red: '',
  },
  mutations: {
    getCompanyShowData(state, payload) {
      console.log(payload)
      getCompanyShow(payload.type).then(res => {
        if (res.code === 0) {
          if (res.data.company_create_time) {
            sessionStorage.setItem('company_create_time', res.data.company_create_time)
          }
          console.log(res, "进没进来")
          if (payload.type === 'all') {

            if (res.data.company_introduce != undefined) {
              res.data.company_introduce = res.data.company_introduce.replace(
                /\n/g,
                '<br/>'
              )
            }
            res.data.sign_data.filter(item => {
              item.sign_introduce = item.sign_introduce.replace(/\n/g, '<br/>')
            })
            state.company_data = res.data
            if (sessionStorage.getItem('odd_job') == 1) {
              state.company_data.jd_data = state.company_data.jd_data.filter(item => {
                return item.odd_status == 2
              })
            }
          }
          if (payload.succeed && typeof payload.succeed === "function") {
            payload.succeed(res)
          }
        }
      })
    },
    contexts(state, played) {
      state.context += ((played.approve_count - state.approve_count) + (played.ask_count - state.ask_count) + (played.account_count - state.account_count))
    },
    oldshujus(state, played) {
      state.approve_count = played.approve_count
      state.ask_count = played.ask_count
      state.account_count = played.account_count
      state.is_unread = played.is_unread
      state.resume_management_red = played.resume_management_red
      sessionStorage.setItem('ask_count', played.ask_count)
    }
  },
  actions: {
    getCompanyShowData(conText, payload) {
      conText.commit('getCompanyShowData', payload)
    },
    polling(ctx, played) {
      console.log(sessionStorage.getItem('lie_token'))
      if (sessionStorage.getItem('lie_token') === null) {
        lieLogin().then((lieRes) => {
          if (lieRes.code === "0") {
            console.log("lieRes", lieRes);
            sessionStorage.setItem("lie_token", lieRes.token);
          }
        });
      }
      var obj = {
        lie_token: sessionStorage.getItem('lie_token')
      }
      polling(obj).then((res) => {
        if (res.code == 0) {
          // 先改变铃铛值
          ctx.commit('contexts', res.data)
          //在把新值付给老值
          ctx.commit('oldshujus', res.data)
        } else {

        }
      })
    }
  }
}
export default module