let module = {
  namespaced: true,
  state: {
    //true  layout 出现黑罩 填写表单
    isPublishForm: false,
    publishInfo: {},
    publishUpdate: false,
    is_show: true,
    
  },
  mutations: {
    emitPublishForm(state, payload) {
      state.isPublishForm = payload.isPublishForm
      if (payload.isPublishForm == false) {
        console.log("1111111")
        state.is_show = false

      }
      state.publishInfo = payload.info
      state.publishUpdate = payload.update
      // console.log(state.publishUpdate)
    },
    is_showChange(state, payload) {
      state.is_show = payload
    },
  }
}
export default module