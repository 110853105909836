import {
  require
} from "././../../utils/http"
//公司展示
function getCompanyShow(data) {
  let obj = {
    "models_info": data
  }
  return require('post', '/api/hr24_ee/company/v1/company_info/', obj)
}
// 公司职位展示
function getCompanyNewShow(data) {
  return require('post', '/api/hr24_ee/company/v1/company_info/', data)
}
//公司展示jd详情
function getCompanyjdinfo(data) {
  let obj = {
    "jd_id": data.id
  }
  return require('post', '/api/hr24_ee/company/v1/jd_info/', obj)
}
//获取套餐数据
function recharge_resume_remind(data) {
  return require('post', '/api/hr24_ee/user/v1/recharge_resume_remind/', data)
}
export {
  getCompanyShow,
  getCompanyjdinfo,
  getCompanyNewShow,
  recharge_resume_remind
}