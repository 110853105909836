import Vue from "vue"
import Router from "vue-router"

import eliteRouter from './eliteRouter'

Vue.use(Router)

const originalPush = Router.prototype.push

Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)

}
const routes = [
  //首页

  //登录
  {
    path: "/login",
    name: "login",
    component: () => import("@/pages/me/login/login"),
    meta: {
      token: "/"
    }
  },
  {
    path: "/qiyezixun",
    name: "qiyezixun",
    component: () => import("@/pages/public/main/index.vue"),
    meta: {
      token: "/"
    }
  },
  {
    path: "/video_number",
    name: "video_number",
    component: () => import("@/pages/ceshi"),
    meta: {
      token: "/"
    }
  },
  //注册
  {
    path: "/register",
    name: "register",
    component: () => import("@/pages/me/login/register"),
    meta: {
      token: "/"
    }
  },
  //充值协议
  {
    path: "/chongzhi",
    name: "chongzhi",
    component: () => import("@/pages/public/agree/chongzhi"),
    meta: {
      token: "/"
    }
  },
  //精英版登陆
  {
    path: "/elite_login",
    name: "elite_login",
    component: () => import("@/elitePages/login/eliteLogin/index.vue"),
    meta: {
      token: "/"
    }
  }, {
    path: "/author",
    name: "author",
    component: () => import("@/pages/me/author"),
    meta: {
      token: "/"
    }
  },
  //协议与隐私条款
  {
    path: "/agree",
    name: "agree",
    component: () => import("@/pages/public/agree/agree"),
    meta: {
      token: "/"
    }
  }, {
    path: "/conceal",
    name: "conceal",
    component: () => import("@/pages/public/agree/conceal"),
    meta: {
      token: "/"
    }
  },
  //公司展示
  {
    path: "/layout",
    name: "layout",
    component: () => import("@/pages/public/layout"),
    children: [{
        path: "/jobdetails",
        name: "jobdetails",
        component: () => import("@/pages/company/jobDetails"),
        meta: {
          title: "返回",
          only: true,
          go: -1,
          parentUrl: '/company'
        }
      },
      {
        path: "/company",
        name: "company",
        component: () => import("@/pages/company/company"),
        meta: {
          title: "公司展示",
          keepAlive: true,
          parentUrl: '/company'
        }
      },



    ]
  },
  //招聘工具
  {
    path: "/layout",
    component: () => import("@/pages/public/layout"),

    children: [{
        path: "/radar",
        name: "radar",
        component: () => import("@/pages/tool/radar"),
        meta: {
          title: '简历雷达',
          parentUrl: '/tool',
          only_oddjob: sessionStorage.getItem('odd_job') == 0 ? false : true
        }
      },
      {
        path: "/createcode",
        name: "createcode",
        component: () => import("@/pages/tool/createcode"),
        meta: {
          title: '生成二维码',
          parentUrl: '/tool',
          only_oddjob: sessionStorage.getItem('odd_job') == 0 ? false : true

        }
      },
      {
        path: "/resumemanage",
        name: "resumemanage",
        component: () => import("@/pages/tool/resumemanage"),
        meta: {
          title: '我的简历',
          keepAlive: true,
          parentUrl: '/tool',
          only_oddjob: sessionStorage.getItem('odd_job') == 0 ? false : true
        }
      },
      //简历库
      {
        path: "/resumebank",
        name: "resumebank",
        component: () => import("@/pages/resume/resumeBank"),
        meta: {
          title: '猎场简历',
          keepAlive: true,
          parentUrl: '/tool',
          only_oddjob: sessionStorage.getItem('odd_job') == 0 ? false : true
        }
      },
      // vla信息
      {
        path: "/vLainfo",
        name: "vLainfo",
        component: () => import("@/pages/tool/vLainfo.vue"),
        meta: {
          title: 'VLa库',
          parentUrl: '/tool',
          only_oddjob: sessionStorage.getItem('odd_job') == 0 ? false : true
        }
      },
      //面试管理
      {
        path: "/InterviewManagement",
        name: "InterviewManagement",
        component: () => import("@/pages/tool/InterviewManagement"),
        meta: {
          title: '面试管理',
          parentUrl: '/tool',
          only_oddjob: sessionStorage.getItem('odd_job') == 0 ? false : true
        }
      },
      //在线聊天
      {
        path: "/onlineChat",
        name: "onlineChat",
        component: () => import("@/pages/tool/onlineChat"),
        meta: {
          title: '在线聊天',
          parentUrl: '/tool',
          only_oddjob: sessionStorage.getItem('odd_job') == 0 ? false : true
        }
      },
      // JD设置
      {
        path: "/tool",
        name: "tool",
        component: () => import("@/pages/tool/publish"),
        meta: {
          title: sessionStorage.getItem('odd_job') == 0 ? 'JD设置' : '零工需求发布',
          parentUrl: '/tool'
        }
      },
      // 零工打卡二维码
      {
        path: "/work_QRcode",
        name: "work_QRcode",
        component: () => import("@/pages/tool/work_QRcode"),
        meta: {
          title: '企业打卡二维码',
          parentUrl: '/tool',
          only_oddjob: sessionStorage.getItem('odd_job') == 0 ? true : false
        }
      },
      // 零工上岗
      // {
      //   path: "/Odd_jobs",
      //   name: "Odd_jobs",
      //   component: () => import("@/pages/tool/Odd_jobs"),
      //   meta: {
      //     title: '零工上岗',
      //     parentUrl: '/tool',
      //     only_oddjob: sessionStorage.getItem('odd_job') == 0 ? true : false
      //   }
      // },
      //猎场简历详情
      // {
      //   path: "/lieChangdetails",
      //   name: "lieChangdetails",
      //   component: () => import("@/pages/tool/lieChangdetails"),
      //   meta: {
      //     title: '返回', only: true, go: -1, parentUrl: '/tool'
      //   }
      // },

      // {
      //   path: "/result",
      //   name: "result",
      //   // component: () => import("@/pages/tool/resumemanage"),
      //   meta: { title: '人才评测', open: false }
      // }, {
      //   path: "/workbook",
      //   name: "workbook",
      //   // component: () => import("@/pages/tool/resumemanage"),
      //   meta: { title: '职场宝典', open: false }
      // },
      //猎场简历详情
      {
        path: "/tooldetails",
        name: "tooldetails",
        component: () => import("@/pages/tool/toolDetails"),
        meta: {
          title: '返回',
          only: true,
          go: -1,
          parentUrl: '/tool'
        }
      },
      {
        path: "/liechangxiangqing",
        name: "liechangxiangqing",
        component: () => import("@/pages/resume/liechangxiangqing"),
        meta: {
          title: '返回',
          only: true,
          go: -1,
          parentUrl: '/tool'
        }
      },




    ]
  },
  //猎场
  {
    path: "/layout",

    component: () => import("@/pages/public/layout"),

    children: [
      //筛选展示广场
      // {
      //   path: "/radarbank",
      //   name: "radarbank",
      //   component: () => import("@/pages/resume/radarbank"),
      //   meta: { title: '简历库', parentUrl: '/resume' }
      // },

      //猎场简历详情
      {
        path: "/resumedetails",
        name: "resumedetails",
        component: () => import("@/pages/resume/resumeDetails"),
        meta: {
          title: '返回',
          only: true,
          go: -1,
          parentUrl: '/resume'
        }


      },
      {
        path: "/resume",
        name: "resume",
        component: () => import("@/pages/resume/resume"),
        meta: {
          title: '猎场',
          keepAlive: true,
          parentUrl: '/resume',
        }
      },
      //猎场简历库详情
      {
        path: "/resumebankdetails",
        name: "resumebankdetails",
        component: () => import("@/pages/resume/resumeBankDetails"),
        meta: {
          title: '返回',
          only: true,
          go: -1,
          parentUrl: '/resume'
        }


      },


    ]
  },
  //推广互动
  {
    path: "/layout",

    component: () => import("@/pages/public/layout"),

    children: [
      //推广海报
      {
        path: "/promotion",
        name: "promotion",
        component: () => import("@/pages/promotion/poster"),
        meta: {
          title: '推广海报',
          parentUrl: '/promotion'
        }
      },
      // 公众号/视频号推广
      {
        path: "/overlapVideo",
        name: "overlapVideo",
        component: () => import("@/pages/promotion/overlapVideo"),
        meta: {
          title: '公众号/视频号推广',
          parentUrl: '/promotion'
        }
      },
      // 企业视频推广 
      {
        path: "/enterpriseVideo",
        name: "enterpriseVideo",
        component: () => import("@/pages/promotion/enterpriseVideo"),
        meta: {
          title: '企业职位视频推广',
          parentUrl: '/promotion'
        }
      },
      // 企业视频推广 添加视频 
      {
        path: "/addVideo",
        name: "addVideo",
        component: () => import("@/pages/promotion/addVideo"),
        meta: {
          title: '返回',
          only: true,
          go: -1,
        }
      },
      //微信企业群二维码
      {
        path: "/company_qr",
        name: "company_qr",
        component: () => import("@/pages/promotion/company_qr"),
        meta: {
          title: '企业微信群二维码推广',
          parentUrl: '/promotion'
        }
      },
      //分享视频
      {
        path: "/share",
        name: "share",
        component: () => import("@/pages/promotion/video"),
        meta: {
          title: '分享视频',
          parentUrl: '/promotion',
          only_oddjob: sessionStorage.getItem('odd_job') == 0 ? false : true
        }
      },
      //Vla
      // {
      //   path: "/Vla",
      //   name: "Vla",
      //   component: () => import("@/pages/promotion/Vla"),
      //   meta: { title: 'Vla', parentUrl: '/promotion' }
      // },


    ]
  },
  //猎之家
  {
    path: "/layout",
    // name: "layout",
    component: () => import("@/pages/public/layout"),

    children: [{
        path: "/hunting_house",
        name: "hunting_house",
        component: () => import("@/pages/me/hunting_house"),
        meta: {
          title: '猎之家',
          parentUrl: '/hunting_house',
          only_oddjob: sessionStorage.getItem('odd_job') == 0 ? false : true
        }
      }, {
        path: "/me",
        name: "me",
        component: () => import("@/pages/me/mine"),
        meta: {
          title: '我的',
          parentUrl: sessionStorage.getItem('odd_job') == 0 ? '/hunting_house' : '/me'
        }
      }, {
        path: "/wallet",
        name: "wallet",
        component: () => import("@/pages/me/wallet"),
        meta: {
          title: '返回',
          only: true,
          go: -1,
        }
      },

      {
        // 使用手册
        path: "/instruction",
        name: "instruction",
        component: () => import("@/pages/me/instruction"),
        meta: {
          title: '使用手册',
          parentUrl: sessionStorage.getItem('odd_job') == 0 ? '/hunting_house' : '/me'
        }
      },
      {
        //设置公司展示
        path: "/setshow",
        name: "setshow",
        component: () => import("@/pages/me/setShow"),
        meta: {
          title: '设置公司展示',
          parentUrl: sessionStorage.getItem('odd_job') == 0 ? '/hunting_house' : '/me',
          only_oddjob: (sessionStorage.getItem("odd_job") == 1 && sessionStorage.getItem("odd_job_status") != 0) ? true : false
        },
      },
      {
        //收支明细
        path: "/shouZdetail",
        name: "shouZdetail",
        component: () => import("@/pages/me/shouZdetail"),
        meta: {
          title: '充值明细',
          parentUrl: sessionStorage.getItem('odd_job') == 0 ? '/hunting_house' : '/me'
        }
      },
      {
        //用户反馈
        path: "/feedback",
        name: "feedback",
        component: () => import("@/pages/me/feedback"),
        meta: {
          title: '用户反馈',
          parentUrl: sessionStorage.getItem('odd_job') == 0 ? '/hunting_house' : '/me'
        }
      },

      {
        path: "/personnel_management",
        name: "personnel_management",
        component: () => import("@/pages/me/personnel_management"),
        meta: {
          title: '返回',
          only: true,
          go: -1,
          parentUrl: '/hunting_house'
        }
      },
      {
        path: "/personnel_xiangqing",
        name: "personnel_xiangqing",
        component: () => import("@/pages/me/hunting_house/personnel_xiangqing"),
        meta: {
          title: '返回',
          only: true,
          go: -1,
          parentUrl: '/hunting_house'
        }
      },
      {
        path: "/sign_in",
        name: "sign_in",
        component: () => import("@/pages/me/sign_in"),
        meta: {
          title: '返回',
          only: true,
          go: -1,
          parentUrl: '/hunting_house'
        }
      },
      {
        path: "/approve_leave",
        name: "approve_leave",
        component: () => import("@/pages/me/approve_leave"),
        meta: {
          title: '返回',
          only: true,
          go: -1,
          parentUrl: '/hunting_house'
        }
      },
      {
        path: "/askleave_record",
        name: "askleave_record",
        component: () => import("@/pages/me/askleave_record"),
        meta: {
          title: '返回',
          only: true,
          go: -1,
          parentUrl: '/hunting_house'
        }
      },
      {
        path: "/management_of_social_security",
        name: "management_of_social_security",
        component: () => import("@/pages/me/management_of_social_security"),
        meta: {
          title: '返回',
          only: true,
          go: -1,
          parentUrl: '/hunting_house'
        }
      },
      {
        path: "/department_management",
        name: "department_management",
        component: () => import("@/pages/me/department_management"),
        meta: {
          title: '返回',
          only: true,
          go: -1,
          parentUrl: '/hunting_house'
        }
      },

    ]
  }, {
    path: '/error',
    name: 'error',
    component: () => import("@/pages/public/404"),
    meta: {
      token: "/"
    }
  }

]
routes.push(...eliteRouter)
console.log("路由", routes)
export default new Router({
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {
        x: 0,
        y: 0
      }
    }
  },
  mode: 'history',
  base: '/dist',
  routes
})