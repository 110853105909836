import 'babel-polyfill'

import Vue from 'vue'
import App from './App.vue'
import router from "./router"
import "./element" // 饿了吗 ui
import "./assets/icons" //svg
import {
  Message,
} from 'element-ui';
import store from "./store/index.js"
import infiniteScroll from 'vue-infinite-scroll'
import clipboard from 'clipboard';
// Swiper 已经挂载上了
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'
import eliteNav from "@/utils/eliteNav";
import vueCropper from "vue-cropper";
Vue.use(
  VueAwesomeSwiper //对Swiper进行了使用
)

// 瀑布流
import waterfall from 'vue-waterfall2'
Vue.use(waterfall)

Vue.use(vueCropper) //基于vue的图片裁剪工具

import CryptoJS from 'crypto-js'
import Print from '@/utils/print'
import wx from 'weixin-js-sdk'
Vue.prototype.$wx = wx
Vue.config.ignoredElements = ['wx-open-launch-weapp']; //注册wx-open-launch-weapp组件
Vue.use(Print);
var key = '&e-yw^a++%(&qy*z'

//精英登录弹窗
Vue.prototype.$elite_login = false;
Vue.prototype.$elite_index = 0;
Vue.prototype.$bus = new Vue()




//注册到vue原型上
Vue.prototype.$clipboard = clipboard;
Vue.use(infiniteScroll)

Vue.config.productionTip = false


Vue.prototype.$copy = function () {
  document.addEventListener('copy', function (e) {
    //取消默认事件，才能修改复制的值
    e.preventDefault()
    //复制的内容
    var copyTxt = `原文链接：${window.location.href}本站禁止复制`
    console.log('复制', e)
    if (e.clipboardData) {
      e.clipboardData.setData('text/plain', copyTxt)
    } else if (window.clipboardData) {
      return window.clipboardData.setData('text', copyTxt)
    }
  })
}
Vue.prototype.$removeCopy = function () {
  document.removeEventListener('copy')
}
//AES加密 
Vue.prototype.$encrypt = function encrypt(word) {

  var key1 = CryptoJS.enc.Utf8.parse(key);
  var srcs = CryptoJS.enc.Utf8.parse(word);
  // encrypt加密：srcs加密内容，key1密钥
  var encrypted = CryptoJS.AES.encrypt(srcs, key1, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  });
  return JSON.parse(encrypted.toString());
}
//AES解密
Vue.prototype.$decrypt = function decrypt(word) {
  var key1 = CryptoJS.enc.Utf8.parse(key);
  // decrypt解密：word解密内容，key1密钥
  var decrypt = CryptoJS.AES.decrypt(word, key1, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  });
  return JSON.parse(CryptoJS.enc.Utf8.stringify(decrypt).toString());
}
/*
下方函数说明：
 1.path 为条登录页之前的页面路由
 
 *

*/
Vue.prototype.$toLogin = function (path) {
  let token = sessionStorage.getItem('elite_token') ? true : false
  if (!token) {
    let arr = ['/elite_index', '/elite_position', '/elite_company']
    if (arr.indexOf(path) == -1) {
      store.commit("showChange", true)
      sessionStorage.setItem("fullpath", JSON.stringify({
        path: to.path,
        query: to.query
      }))
    }
  }
}

let login_router = ["/elite_index", "/elite_position", "/elite_company", "/elite_position_details", 'elite_company_details']
let token_router = []
eliteNav.map(item => {
  token_router.push(item.router)
})

router.beforeEach((to, from, next) => {
  let token = sessionStorage.getItem('token') ? true : false
  let elite_token = sessionStorage.getItem('elite_token') ? true : false
  let is_elite = sessionStorage.getItem("is_elite")
  let has_resume = sessionStorage.getItem("has_resume") || 0
  if (to.meta.token == '/') {
    sessionStorage.setItem("is_elite", 1)
  }
  if (token && is_elite == 0) {
    console.log("token && is_elite == 0")
    if (!to.meta.token) {
      console.log("不是精英")
      next()
    } else {
      next()
    }
  } else if (elite_token && is_elite == 1) {
    console.log("elite_token && is_elite == 1")

    if (is_elite && has_resume == 0) {
      console.log("精英无简历")
      next()
    } else if (is_elite && has_resume == 1) {
      console.log("精英有简历")
      if (to.meta.token == '/') {
        next()
      } else if (to.meta.token != '/') {
        next('/elite_index')
      }
    }
  } else {
    if (to.meta.token == '/') {
      console.log("to.meta.token == '/'")
      next()
    } else {
      if (token) {
        next()
      } else {
        next("/elite_index")
      }
    }
  }
})
new Vue({
  store,
  router,
  render: h => h(App),
}).$mount('#app')