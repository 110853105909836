import {
  require
} from "./../../utils/http"
//我的首页
function setMyHome() {
  return require('post', '/api/hr24_ee/company/v1/main/')
}
//设置公司展示
function setShowApi(data) {
  let obj = {
    update_data: data
  }

  console.log(data)

  return require('post', '/api/hr24_ee/company/v1/company_detail_update/', obj)
}



//展示城市数据
function showCity(data) {
  return require('post', '/api/public/v1/show_client_place/', data)
}
//风云人物
function setSignApi(data) {
  let obj = {
    sign_list: data
  }
  return require('post', '/api/hr24_ee/company/v1/company_sign_update/', obj)
}
//公司相册管理
function setImgApi(data) {
  let obj = {
    img_list: data
  }
  return require('post', '/api/hr24_ee/company/v1/company_img_update/', obj)
}
//经纬度
function getLocation(data) {
  return require('post', '/api/hr24_ee/company/v1/site_location/', data)
}
//设置公司展示热线电话
function setting_hotline(data) {
  return require('post', '/api/hr24_ee/company/v1/setting_hotline/', data)
}
//首页公司咨询接口
function create_consult(data) {
  return require('post', '/api/hr24_ee_admin/auth/v1/create_consult/', data)
}
//设置公司行业列表
function show_industry_json(data) {
  return require('post', '/api/public/v1/show_industry_json/', data)
}
//首页地区所有  筛选器
function show_place_json(data) {
  return require('post', '/api/public/v1/show_place_json/', data)
}

//企业反馈
function feedback(data) {
  return require('post', '/api/hr24_elite/v1/feedback/', data)
}
//猎场充值记录
function seed_pay_record(data) {
  return require('post', '/api/hr24_ee/liechang/v1/seed_pay_record/', data)
}
//猎场充值接口
function wx_native_order(data) {
  return require('post', '/api/hr24_ee/liechang/v1/wx_native_order/', data)
}
//猎场充值成功
function seed_pay(data) {
  return require('post', '/api/hr24_ee/liechang/v1/seed_pay/', data)
}
//猎场充值查询订单接口
function native_order_query(data) {
  return require('post', '/api/hr24_ee/liechang/v1/native_order_query/', data)
}
//猎场暂时扣除简历红包接口
function faker_red_packet(data) {
  return require('post', '/api/hr24_ee/liechang/v1/faker_red_packet/', data)
}
//猎场请求是否有未完成订单
function has_unpaid_order(data) {
  return require('post', '/api/hr24_ee/liechang/v1/has_unpaid_order/', data)
}
//收支明细支付未支付订单接口
function reopen_order(data) {
  return require('post', '/api/hr24_ee/liechang/v1/reopen_order/', data)
}
//收支明细取消未支付订单
function restore_red_packet(data) {
  return require('post', '/api/hr24_ee/liechang/v1/restore_red_packet/', data)
}
//获取套餐数据
function resume_set_meal(data) {
  return require('post', '/api/hr24_ee/liechang/v1/resume_set_meal/', data)
}
//获取套餐数据
function recharge_resume_remind(data) {
  return require('post', '/api/hr24_ee/user/v1/recharge_resume_remind/', data)
}
//图片上传接口
function files_upload(data) {
  return require('post', '/api/hr24_ee/user/v1/files_upload/', data, false)
}
// 猎之家人员管理
function staff_list(data) {
  return require('get', '/api/liezhijia/v1/staff_list/', data)
}
// 猎之家人员详情
function staff_detail(data) {
  return require('get', '/api/liezhijia/v1/staff_detail/', data)
}
// 猎之家离职
function staff_shan(data) {
  return require('DELETE', '/api/liezhijia/v1/staff_list/', data, false)
}
// 猎之家添加
function create_user(data) {
  return require('post', '/api/liezhijia/v1/create_user/', data)
}
// 猎之家修改
function staff_Put(data) {
  return require('PUT', '/api/liezhijia/v1/staff_list/', data, false)
}

// 猎之家打卡
function personnel(data) {
  return require('GET', '/api/liezhijia/v1/personnel/', data)
}

//猎之家按天请假审批
function leave_audit(data) {
  return require('GET', '/api/liezhijia/v1/leave_audit/', data)
}
//猎之家审批按天请假
function leave_gai(data) {
  return require('POST', '/api/liezhijia/v1/leave_audit/', data)
}
//猎之家按天请假审批
function h_leave_audit(data) {
  return require('GET', '/api/liezhijia/v1/h_leave_audit/', data)
}

function h_gai(data) {
  return require('POST', '/api/liezhijia/v1/h_leave_audit/', data)
}
// 猎之家需要审批数量
function remind_leave(data) {
  return require('POST', '/api/liezhijia/v1/remind_leave/', data)
}

// 猎之家天请假审批数量
function already_leave_audit(data) {
  return require('GET', '/api/liezhijia/v1/already_leave_audit/', data)
}
// 猎之家小时请假审批数量
function already_h_leave_audit(data) {
  return require('GET', '/api/liezhijia/v1/already_h_leave_audit/', data)
}

// 上传图片
function create_url(data) {
  return require('GET', '/api/liezhijia/v1/create_url/', data)
}
// 企业钱包明细
function wallet_detail(data) {
  return require('GET', '/api/hrswork/v1/wallet_detail/', data)
}

export {
  recharge_resume_remind,
  resume_set_meal,
  restore_red_packet,
  reopen_order,
  has_unpaid_order,
  faker_red_packet,
  native_order_query,
  seed_pay,
  wx_native_order,
  seed_pay_record,
  setShowApi,
  setting_hotline,
  setSignApi,
  setImgApi,
  setMyHome,
  getLocation,
  showCity,
  create_consult,
  show_industry_json,
  show_place_json,
  feedback,
  files_upload,
  staff_list,
  staff_detail,
  staff_shan,
  create_user,
  staff_Put,
  personnel,
  leave_audit,
  leave_gai,
  h_leave_audit,
  h_gai,
  remind_leave,
  already_leave_audit,
  already_h_leave_audit,
  create_url,
  wallet_detail
}