// import { getCompanyShow } from '@/api/company/company'
import {
    get_client_city,
    jd_pc_home,
    jd_city_list
} from "@/api/eliteApi";
let module = {
    // namespaced: true,
    state: {
        is_show: false, //是否显示精英版登录弹窗
        elite_index: 0, //操作精英导航KEY
        name: "",
        token: "",
        index: 0,
        pic_url: '',
        is_show_city: false, //首页筛选城市弹窗是否显示
        eliteIndex_city: "",
        resumes_jd: [{
                id: "1",
                jd: "室内设计",
                salary: "10000-15000 元",
                company: "四川二四猎网络科技有限公司",
            },
            {
                id: "1",
                jd: "新媒体运营",
                salary: "10000-25000 元",
                company: "云南商通企业服务有限公司",
            },
            {
                id: "1",
                jd: "运维开发工程师",
                salary: "10000-25000 元",
                company: "云南商通企业服务有限公司",
            },
            {
                id: "1",
                jd: "销售经理",
                salary: "15000-30000 元",
                company: "山西宇晨企业管理服务有限公司",
            },
            {
                id: "1",
                jd: "运营总监",
                salary: "12500-25000 元",
                company: "邯郸市立航人力资源服务有限公司",
            },
            {
                id: "1",
                jd: "市场营销",
                salary: "10000-30000 元 ",
                company: "宜信普惠信息咨询（北京）有限公司绵阳分公司",
            },
            {
                id: "1",
                jd: "副总裁/副总经理/VP",
                salary: "30000-40000 元",
                company: "潍坊善之善企业管理服务有限公司",
            },
            {
                id: "1",
                jd: "合伙人",
                salary: "20000-50000 元",
                company: "南京丰购网络科技有限公司",
            },
            {
                id: "1",
                jd: "设计总监",
                salary: "10000-15000 元",
                company: "南阳市业之峰装饰有限公司",
            },
            {
                id: "1",
                jd: "PHP",
                salary: "12000-14000 元",
                company: "湖南企捷科技集团有限公司",
            },
            {
                id: "1",
                jd: "总裁/总经理/CEO",
                salary: "20000-30000 元",
                company: "河南北斗人力资源管理有限公司",
            },
            {
                id: "1",
                jd: "移动web前端",
                salary: "10000-15000 元",
                company: "湖南企捷科技集团有限公司",
            },
        ],
        cityIndex: 0,
        keyphoneticOrdering: "A",
        loading: true,
        index_loading: true,
        resumeIndex: 0
    },
    mutations: {
        resumeIndex(state, data) {
            state.resumeIndex++
        },
        index_loading(state, data) {
            state.index_loading = data
        },
        keyphoneticOrdering(state, data) {
            state.keyphoneticOrdering = data
        },
        showChange(state, payload) {
            //操作精英登录弹窗是否显示
            state.is_show = !payload
            state.is_show = payload
        },
        is_show_cityChange(state, payload) {
            //操作精英登录弹窗是否显示
            state.is_show_city = payload
        },
        eliteIndexChage(state, payload) {
            //操作精英登录弹窗是否显示
            // state.elite_index++
            state.name = sessionStorage.getItem("weixin_nickname") || "精英";
            state.token = sessionStorage.getItem("elite_token") ? true : false;
            state.elite_index++
            state.pic_url = sessionStorage.getItem("pic_url") ?
                sessionStorage.getItem("pic_url") :
                require("@/assets/images/elite/tou.png");
        },
        jd_pc_home(state, data) {
            state.resumes_jd = []
            state.resumes_jd = data
        },
        cityIndexChange(state, data) {

        },
        sessionStorageSet(state, data) {
            // state.cityIndex = data.cityIndex
            console.log(data)
            state.cityIndex = data.cityIndex
            state.eliteIndex_city = data.eliteIndex_city
            state.keyphoneticOrdering = data.keyphoneticOrdering
            sessionStorage.setItem( "place_ip", JSON.stringify(data) );
        },

        listChange(state, data) {
            state.keyphoneticOrdering = data;
            state.list = state.cityList[state.keyphoneticOrdering]
        },
    },
    actions: {
        eliteIndexChage(conText, payload) {
            console.log("cahbshbsahcb")
            conText.commit('eliteIndexChage', payload)
        },
        get_client_city({ dispatch, commit }, data) {
            // console.log(data)
            let province_code = ""
            let city_code = ""
            let place_ip = JSON.parse(sessionStorage.getItem("place_ip"))
            // console.log(place_ip,"cacacas")
            if (place_ip == null) {
                console.log('aaa')
                get_client_city().then((res) => {
                    if (!res.code) {
                        console.log(res);
                        // province_code = res.data.province_code      修改
                        city_code = res.data.city_code
                        dispatch("jd_pc_home", city_code);
                        commit("keyphoneticOrdering", res.data.first_char)
                        commit("sessionStorageSet", {
                            keyphoneticOrdering: res.data.first_char,
                            province_code: res.data.province_code,
                            city_code: res.data.city_code,
                            cityIndex: res.data.city_code,
                            eliteIndex_city: res.data.city_name
                        })
                    }
                });
            } else {
                // console.log('发噶监控和三个飞机卡怪飞机卡怪克己奉公阿库福工卡更加开放噶空间三个滑块')
                province_code = data.province_code
                city_code = data.city_code
                console.log(data)
                dispatch("jd_pc_home", city_code);
                commit("sessionStorageSet", data)
                commit("is_show_cityChange", false)
            }
        },
        jd_pc_home({
            dispatch,
            commit
        }, data) {
            commit("index_loading", true)
            console.log("casjbasbcasb")
            jd_pc_home({
                select_data: {
                    place_id: data
                }
            }).then((res) => {
                console.log(res);
                if (!res.code) {
                    commit("jd_pc_home", res.data.data)
                    commit("index_loading", false)
                }
            });
        },

    }
}
export default module