import Vue from "vue"
import Vuex from "vuex"
Vue.use(Vuex)
import message from "./modules/message"
import tool from "./modules/tool"
import company from "./modules/company"
import companyInfo from "./modules/companyInfo"
import eliteLOgin from "./modules/elite_login"
import qiuzhi from "./modules/qiuzhi"


export default new Vuex.Store({
  modules: {
    message,
    tool,
    company,
    companyInfo,
    eliteLOgin,
    qiuzhi
  },
  state: {
    company_logo: '',
    company_name: '',

  },
  mutations: {
    changeLogo(state, payload) {
      console.log(payload)
      state.company_logo = payload.logo
      state.company_name = payload.name
    },

  }

})