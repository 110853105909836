import {
  require
} from './../../utils/http'
//猎场

function getResumeData(data) {
  // let obj = {
  //   scroll_id: data.scroll_id,
  //   page: data.page,
  //   gender: data.gender,
  //   industry_id: data.industry_id,
  //   min_age: data.min_age,
  //   max_age: data.max_age

  // }
  return require('post', '/api/hr24_ee/liechang/v1/liechang_list_new/', data)
}
//猎场详情

function getResumeDetails(data) {

  return require('post', '/api/hr24_ee/liechang/v1/liechang_detail_new/', data)
}
//猎场详情

function downResume(data) {

  return require('post', '/api/hr24_ee/liechang/v1/download_resume_new/', data)
}

//展示城市数据
function showCity(data) {
  return require('post', '/api/public/v1/show_client_place/', {})
}

//查询
function getsearchResumeData(data) {
  // let obj = {
  //   scroll_id: data.scroll_id,
  //   page: data.page,
  //   expect_place_id: data.expect_place_id,
  //   search_str: data.search_str
  // }
  return require('post', '/api/hr24_ee/liechang/v1/search_new/', data)
}
//简历库
function getResumeBankData(data) {

  return require('post', '/api/hr24_ee/liechang/v1/resume_list_new/', data)
}
//简历库详情
function getResumeBankDetailsData(data) {
  let obj = {
    resume_id: data.resume_id
  }
  return require('post', '/api/hr24_ee/liechang/v1/resume_detail_new/', obj)
}
//简历是否已下载
function getIsDown() {

  return require('post', '/api/hr24_ee/liechang/v1/liechang_download/')
}
//雷达列表
function getradar(data) {

  return require('post', '/api/hr24_ee/tool/v1/radar_all_list_pc/', data)
}
//下载雷达线索
function downradar(data) {
  return require('post', '/api/hr24_ee/tool/v1/download_radar/', data)
}
//
function copy_wechat_id(data) {
  return require('post', '/api/hr24_ee/tool/v1/copy_wechat_id/ ', data)
}

function where_resume(data) {
  return require('post', '/api/hr24_ee/liechang/v1/where_resume/', data)
}

//猎场显示企业所有上架职位
function jd_list(data) {
  return require('get', '/api/hr24_ee/liechang/v1/jd_list/', data)
}


export {

  getResumeData,
  getResumeBankData,
  showCity,
  getsearchResumeData,
  getResumeBankDetailsData,
  getResumeDetails,
  downResume,
  getIsDown,
  getradar,
  downradar,
  copy_wechat_id,
  where_resume,
  jd_list
}