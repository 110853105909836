<template>
  <div class="login_elite">
    <div class="login_form">
      <div class="close">
        <span class="el-icon-circle-close" @click="guanbi"></span>
      </div>
      <div>精 英 登 录</div>
      <div v-show="activeName == 'Wecode'">
        <div id="code">
          <div v-if="is_showRefresh" @click="refresherCode">
            二维码失效，点击刷新
          </div>
          <img :src="qr_url" alt="" />
        </div>
      </div>

      <div v-show="activeName == 'phone'" class="shuru">
        <div class="ipt_wrap">
          <input
            class="ipt"
            type="text"
            placeholder="请输入手机号"
            maxlength="11"
            v-model.trim="user.username"
          />
        </div>
        <div class="ipt_wrap">
          <div>
            <input
              class="yanzhengma"
              type="text"
              placeholder="请输入验证码"
              v-model.number="user.password"
            />
            <div
              :class="{ btnY: !disabled, false: disabled }"
              :disabled="disabled"
              type="text"
              @click="send_yan"
            >
              {{ !disabled ? "发送验证码" : time + "秒后重发" }}
            </div>
          </div>
        </div>
        <el-button class="enter" :loading="loading" @click="enterIN"
          >登&emsp;录</el-button
        >
      </div>
      <div class="agreement" v-if="activeName == 'phone'">
        <el-checkbox v-model="checkbox">我同意</el-checkbox>

        <span @click="$router.push('/conceal')">《24猎隐私政策》</span>
        和
        <span @click="$router.push('/agree')">《24猎使用协议》</span>
      </div>
      <div class="agreement" v-else>
        <span>登录表示同意</span>
        <span @click="$router.push('/conceal')">《24猎隐私政策》</span>
        和
        <span @click="$router.push('/agree')">《24猎使用协议》</span>
      </div>
      <div class="jiao" @click="switchCilck">
        <!-- 切换手机和二维码 -->
        <p class="title_top">
          {{ activeName == "Wecode" ? "手机号登录" : "微信扫码登录" }}
        </p>
        <div>
          <img
            v-if="activeName == 'Wecode' && 1"
            src="@/assets/images/elite/login_phone01.png"
            alt
          />
        </div>
        <div>
          <img
            v-if="activeName != 'Wecode' && 1"
            src="@/assets/images/elite/login_phone.png"
            alt
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  send_code,
  login_pc,
  check_code,
  pc_login_qr,
  refresh_login,
} from "@/api/eliteApi";
import { company_scale } from "../../utils/searchtype";
import { mapMutations } from "vuex";

export default {
  data() {
    return {
      user: {
        username: "",
        password: "",
      },
      loading: false,
      disabled: false,
      time: 60,
      duanXin: "",
      code: "",
      activeName: "Wecode",
      set: null,
      checkbox: true,
      qr_url: "#",
      qr_parameter: "",
      index_set: 0,
      setInterval: null,
      is_showRefresh: false,
    };
  },
  created() {
    this.pc_login_qr();
  },
  watch: {
    $route() {
      this.showChange(false);
      clearInterval(this.setInterval);
      clearInterval(this.set);
    },
  },
  mounted() {
    // this.setWxerwma();
    this.$nextTick(() => {
      const body = document.querySelector("body");
      let gao = document.body.scrollTop;
      console.log("文档被卷取得高度", gao, body);
      this.$el.style.top = gao + "px";
      if (body.append) {
        body.append(this.$el);
      } else {
        body.appendChild(this.$el);
      }
    });
  },
  methods: {
    ...mapMutations({
      showChange: "showChange",
      resumeIndex: "resumeIndex",
      qiuzhiChange: "qiuzhi/qiuzhiChange",
    }),
    guanbi(val) {
      clearInterval(this.setInterval);
      clearInterval(this.set);
      let oldPath = JSON.parse(sessionStorage.getItem("oldPath"));
      this.showChange(false);
      if (this.$route.path == "/elite_position_details") {
        console.log("刷新页面、resume");
        return false;
      }
      if (oldPath.path == "/elite_login" || oldPath.path == "/elite_chat") {
        this.$router.push({
          path: "/elite_index",
        });
        return false;
      }
      if (this.$route.path == "/elite_video") {
        return false;
      }
      this.$router.push(oldPath);
    },
    // setWxerwma() {
    //   const s = document.createElement("script");
    //   s.type = "text/javascript";
    //   s.src = "https://res.wx.qq.com/connect/zh_CN/htmledition/js/wxLogin.js";
    //   const wxElement = document.body.appendChild(s);
    //   wxElement.onload = function () {
    //     var obj = new WxLogin({
    //       id: "code", // 需要显示的容器id
    //       appid: "wxe736193149198a99", // 公众号appid wx*******
    //       scope: "snsapi_login", // 网页默认即可
    //       redirect_uri: "http://192.168.1.3:8080/dist/elite_login", // 授权成功后回调的url
    //       state: Math.ceil(Math.random() * 1000), // 可设置为简单的随机数加session用来校验
    //       style: "black", // 提供"black"、"white"可选。二维码的样式
    //       // href: "", // 外部css文件url，需要https
    //     });
    //   };
    // },
    switchCilck() {
      console.log("asc");
      this.activeName == "phone"
        ? (this.activeName = "Wecode")
        : (this.activeName = "phone");
      clearInterval(this.setInterval);
      if (this.activeName == "Wecode") {
        this.pc_login_qr();
      }
    },

    refresherCode() {
      //刷新二维码

      this.pc_login_qr();
      // clearInterval(this.setInterval);
    },
    pc_login_qr() {
      pc_login_qr().then((res) => {
        console.log(res);
        if (!res.code) {
          this.qr_parameter = res.data.qr_parameter;
          this.qr_url = res.data.qr_url;
          this.setIntervalFun();
          this.is_showRefresh = false;
        }
      });
    },
    //轮询
    refresh_login() {
      refresh_login({ qr_parameter: this.qr_parameter }).then((res) => {
        // 1.  code：205002，二维码失效
        //  2.  code：205003， 还未允许登录  继续轮询
        //   3.  code：0， 允许登录，返回 username（手机号）用这个去请求登录接口
        switch (res.code) {
          case 205002:
            clearInterval(this.setInterval);
            this.is_showRefresh = true;
            break;
          case 205004:
            if (this.$route.path == "/elite_video") {
              this.$message.warning("您已拒绝登录");
              this.refresherCode()
              this.showChange(false);
            } else {
              this.$message.warning("您已拒绝登录，返回首页");
              clearInterval(this.setInterval);
              this.$router.push({
                path: "/elite_index",
              });
            }
            break;
          case 0:
            clearInterval(this.setInterval);
            this.login_pc(res.data.username);
            break;
        }
        console.log(res);
      });
    },
    //扫描二维码轮询
    setIntervalFun() {
      this.setInterval = setInterval(() => {
        this.index_set++;
        this.refresh_login(this.qr_parameter);
      }, 2000);
    },
    gorouter(torouter) {
      this.showChange(false);
      this.$router.push({
        path: torouter,
      });
    },
    quxiao() {
      console.log("点击");

      this.$router.push(JSON.parse(sessionStorage.getItem("oldPath")));
      this.showChange(false);
    },
    send_yan() {
      if (this.disabled) {
        return;
      }
      if (!/^1[3456789]\d{9}$/.test(this.user.username)) {
        this.$message({
          message: "请填写手机号",
          type: "warning",
        });
        return false;
      }
      this.disabled = true;
      this.set = setInterval(() => {
        console.log("aa");
        this.time--;
        if (this.time === 0) {
          this.disabled = false;
          this.time = 60;
          clearInterval(this.set);
        }
      }, 1000);
      if (this.disabled) {
        send_code({ status: 1, phone: this.user.username }).then((res) => {
          console.log(res);
          if (!res.code) {
          }
        });
      }
    },
    enterIN() {
      if (this.loading === false) {
        this.enter();
      } else {
        return;
      }
    },
    enter() {
      // console.log(this.$store);
      console.log();
      console.log(this.$route);

      // this.$elite_index = true;
      // this.$store.commit("elite_login", false);

      console.log(this.checkbox);

      if (!this.checkbox) {
        this.$message({
          message: "请勾选隐私政策和用户使用协议",
          type: "warning",
        });
        return;
      }

      if (!/^1[3456789]\d{9}$/.test(this.user.username)) {
        this.$message({
          message: "请填写手机号",
          type: "warning",
        });
        return;
      }
      if (this.user.password === "") {
        this.$message({
          message: "请填写验证码",
          type: "warning",
        });
        return;
      }
      this.loading = true;
      //测试
      check_code({
        phone: this.user.username,
        code: this.user.password,
      }).then((res) => {
        if (!res.code) {
          console.log("验证码正确");
          clearInterval(this.set);
          clearInterval(this.setInterval);
          this.login_pc(this.user.username);
        } else {
          this.loading = false;
          this.$message.warning("验证码不正确");
        }
      });
    },
    login_pc(username) {
      login_pc({ username }).then((res) => {
        if (!res.code) {
          if (res.data.career_progress == 0) {
            sessionStorage.setItem("career_progress", res.data.career_progress);
            this.qiuzhiChange(true);
          }
          sessionStorage.setItem("elite_token", res.data.token);
          sessionStorage.setItem("elite_id", res.data.elite_id);
          sessionStorage.setItem("username", res.data.username);
          sessionStorage.setItem("resume_id", res.data.resume_id);
          sessionStorage.setItem("has_resume", res.data.has_resume);
          sessionStorage.setItem("pic_url", res.data.pic_url);
          sessionStorage.setItem("weixin_nickname", res.data.weixin_nickname);
          this.$root.elite_login = false;
          this.$store.dispatch("eliteIndexChage");
          this.showChange(false);
          if (res.data.has_resume == 0 && this.$route.path != "/elite_resume") {
            this.$router.push({
              path: "/elite_resume",
            });
            return false;
          } else if (this.$route.path == "/elite_video") {
            return false;
          } else {
            this.$router.go(0);
            return false;
          }
        }
      });
    },
  },

  beforeDestroy() {
    clearInterval(this.set);
    clearInterval(this.setInterval);
  },
};
</script>

<style scoped>
html /deep/ body {
  position: relative;
}
.login_elite {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 100;
  cursor: pointer;
}
.login_form {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 400px;
  height: 460px;
  margin-left: -200px;
  margin-top: -230px;
  box-sizing: border-box;
  padding: 49px 0px 30px;
  /* text-align: center; */
  background-color: #fff;
  box-sizing: border-box;
  /* border-radius: 10px; */
  position: relative;
}
.login_form > .close {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
}
.login_form > div:nth-child(2) {
  font-size: 23px;
  color: #666;
  text-align: center;
  margin-bottom: 12px;
}
.ipt_wrap {
  /* padding-left: 50%; */
  /* text-align: center; */
}
.shuru {
  width: 316px;
  margin: auto;
  margin-top: 30px;
  /* background: chartreuse; */
}
.ipt {
  /* transform: translate(-50%); */
  /* display: block; */
  /* width: 100%; */
  height: 42px;
  width: 100%;
  box-shadow: inset 0 24px 64px 0 rgba(14, 20, 33, 0.1);
  border-radius: 16px;
  text-indent: 20px;
  font-size: 16px;
  color: #666;
}
.ipt_wrap > div {
  /* transform: translate(-50%); */
  /* display: block; */
  margin-top: 36px;
  height: 42px;
  width: 100%;
  margin-right: 12px;
  box-shadow: inset 0 24px 64px 0 rgba(14, 20, 33, 0.1);
  border-radius: 10px;
  color: #666;
  overflow: hidden;
}
.yanzhengma {
  height: 42px;
  background: none;
  text-indent: 20px;
  font-size: 16px;
}
.btnY {
  line-height: 42px;
  float: right;
  padding-right: 20px;
  cursor: pointer;
  font-size: 14px;
}
.false {
  line-height: 42px;
  float: right;
  padding-right: 20px;
  cursor: pointer;
  color: rgb(248, 52, 52) !important;
  /* width: 100px; */
  font-size: 14px;
}
.agreement {
  width: 333px;
  margin: auto;
  text-align: center;
  margin-bottom: 20px;
  font-size: 14px;
  color: #e02e0e;
}

.agreement span {
  cursor: pointer;
  font-size: 14px;
}
.enter,
.enter:hover {
  width: 100%;
  height: 50px;
  border-radius: 10px;
  background-color: #ff8f00;
  color: #fff;
  border: none;
  font-size: 18px;
  margin-top: 60px;
  margin-bottom: 60px;
}
/* .enter:hover {
  background-color: #2468f2;
} */
/* 注册 */
.reg {
  font-size: 14px;
  text-align: right;
  cursor: pointer;
  color: #666;
}
.reg:hover {
  color: #ff8f00;
}

div /deep/ .el-checkbox__inner:hover {
  border-color: #ff8f00;
}

div/deep/ .el-checkbox__input.is-checked + .el-checkbox__label {
  color: #ff8f00;
}
div/deep/ .el-checkbox__input.is-checked .el-checkbox__inner {
  background-color: #ff8f00;
  border-color: #ff8f00;
}
div/deep/ .el-checkbox__input.is-focus .el-checkbox__inner {
  border-color: #ff8f00;
}

.jiao {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 28px;
  height: 60px;
  /* background: rgb(204, 130, 130); */
  cursor: pointer;
  padding: 15px;
}
.jiao img {
  width: 20px;
  /* height: 30px; */
}
.close {
  position: absolute;
  top: 0px;
  right: 20px;
  font-size: 20px;
  color: #666;
  cursor: pointer;
  font-weight: bold;
}
#code {
  text-align: center;
  width: 250px;
  height: 250px;
  /* text-align: center; */
  /* padding-top: 22px; */
  margin: auto;
  margin-top: 28px;
  margin-bottom: 45px;
  position: relative;
}

#code img {
  height: 100%;
}
#code div {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  line-height: 250px;
  background: rgb(105 105 105 / 80%);
  color: aliceblue;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
}
.title_top {
  position: absolute;
  width: 109px;
  left: 45px;
  color: rgb(75, 74, 74);
  top: -5px;
  text-align: center;
  font-size: 14px;
  line-height: 24px;
  font-weight: bold;
  /* background: rgba(8, 8, 8, 1.7); */
  border-radius: 4px;
  -webkit-box-shadow: rgb(230 230 230) 0px 0px 2px 2px;
  box-shadow: rgb(230 230 230) 0px 0px 2px 2px;
}
</style>