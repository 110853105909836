import {
  require
} from './../utils/http'
//登录
function login(data) {
  return require('post', '/api/hr24_ee/user/v1/login_pc/', data)
}
//零工账号登录
function odd_login_pc(data) {
  return require('post', '/api/hr24_ee/user/v1/odd_login_pc/', data)
}
// 猎之家登录
function lieLogin() {
  return require('post', '/api/liezhijia/v1/get_token/', )
}
//注册
function register(data) {
  return require('post', '/api/hr24_ee/user/v1/new_register/', data)
}
// 零工账号注册
function odd_register(data) {
  return require('post', '/api/hr24_ee/user/v1/odd_register/', data)
}
//验证码
function sendCodeApi(data) {

  return require('post', '/api/hr24_ee/user/v1/send_code/', data)
}
//校验验证码
function check_code(data) {
  return require('post', '/api/hr24_ee/user/v1/check_code/', data)
}
//登录通知
function show_inform(data) {
  return require('post', '/api/hr24_ee/user/v1/show_inform/', data)
}
//video_nuber
function get_signature(data) {
  return require('post', '/api/hr24_ee/share/v1/get_signature/', data)
}

// 新功能上线使用说明
function imgs(data) {
  return require('post', '/api/public/v1/product_manual/', data)
}

//轮询接口
function polling(data) {
  return require('post', '/api/hr24_ee/user/v1/polling/', data)
}
export {
  login,
  register,
  sendCodeApi,
  check_code,
  show_inform,
  get_signature,
  lieLogin,
  imgs,
  polling,odd_register,odd_login_pc
}