import {
  require
} from "././../../utils/http"
//登陆页面
export function getCompanyShow(data) {
  return require('post', '/api/hr24_ee/company/v1/company_info/', data)
}
//登录接口
export function login_pc(data) {
  return require('post', '/api/hr24_elite/v1/login_pc/', data)
}

//微信扫码登录通过code
export function redirect_data(data) {
  return require('post', '/api/hr24_elite/v1/redirect_data/', data)
}


//发送验证码
export function send_code(data) {
  return require('post', '/api/hr24_ee/user/v1/send_code/', data)
}
// 验证验证码
export function check_code(data) {
  return require('post', '/api/hr24_ee/user/v1/check_code/', data)
}
// 首页职位列表
export function jd_pc_home(data) {
  return require('post', '/api/hr24_elite/v1/jd_pc_home/', data)
}
// 获取用户地区id
export function get_client_city(data) {
  return require('post', '/api/hr24_elite/v1/get_client_city/', data)
}
// 
export function jd_city_list(data) {
  return require('post', '/api/hr24_elite/v1/jd_city_list/', data)
}






//零工职位页面
export function odd_jd_list(data) {
  return require('post', '/api/hr24_elite/v1/odd_jd_list/', data)
}
//职位页面
export function jd_list(data) {
  return require('post', '/api/hr24_elite/v1/jd_list/', data)
}
//筛选职位列表
export function show_position_json(data) {
  return require('get', '/api/public/v1/show_position_json/', data)
}
//投递简历
export function send_resume(data) {
  return require('post', '/api/hr24_elite/v1/send_resume/', data)
}
//职位详情
export function jd_info(data) {
  return require('post', '/api/hr24_elite/v1/jd_info/', data)
}
//职位详情进雷达
export function jd_info_pc(data) {
  return require('post', '/api/hr24_elite/v1/jd_info_pc/', data)
}
//职位详情进雷达
export function show_subject_json(data) {
  return require('post', '/api/public/v1/show_subject_json/', data)
}




//公司页面

//公司列表
export function odd_company_list(data) {
  return require('post', '/api/hr24_elite/v1/odd_company_list/', data)
}
//公司列表
export function company_list(data) {
  return require('post', '/api/hr24_elite/v1/company_list/', data)
}
//地区筛选列表
//参数   unlimited   区级地区是否有不限 0没不限 1有不限
export function show_client_place(data) {
  return require('post', '/api/public/v1/show_client_place/ ', data)
}
//公司详情
export function company_info(data) {
  return require('post', '/api/hr24_elite/v1/company_info/', data)
}

// 行业列表
export function show_industry_json(data = {}) {
  return require('post', '/api/public/v1/show_industry_json/', data)
}

// 简历页面
export function resume_info(data) {
  return require('post', '/api/hr24_elite/v1/resume_info/', data)
}
//创建简历
export function create_resume(data) {
  return require('post', '/api/hr24_elite/v1/create_resume/', data)
}
//修改简历上下架状态
export function resume_hide(data) {
  return require('post', '/api/hr24_elite/v1/resume_hide/', data)
}
//修改简历上下架状态
export function show_resume_hide(data) {
  return require('post', '/api/hr24_elite/v1/show_resume_hide/', data)
}

//更新简历基础信息    
export function basic_update(data) {
  return require('post', '/api/resume/v1/basic_update/', data)
}
//更新简历求职期望信息    
export function expect_position_update(data) {
  return require('post', '/api/resume/v1/expect_position_update/', data)
}
//更新简历教育经历  
export function education_experience_update(data) {
  return require('post', '/api/resume/v1/education_experience_update/', data)
}
//更新工作经历 
export function work_experience_update(data) {
  return require('post', '/api/resume/v1/work_experience_update/', data)
}




//聊天页面

//聊天列表
export function chatting_list(data) {
  return require('post', '/api/hr24_elite/v1/chatting_list/', data)
}
//聊天内容
export function chatting_detail(data) {
  return require('post', '/api/hr24_ee/tool/v1/chatting_detail/', data)
}
//是否刷新页面
export function refresh_chatting(data) {
  return require('post', '/api/hr24_elite/v1/refresh_chatting/', data)
}
//发送消息
export function send_chatting(data) {
  return require('post', '/api/hr24_ee/tool/v1/send_chatting/', data)
}



//意见反馈
export function feedback(data) {
  return require('post', '/api/hr24_elite/v1/feedback/', data)
}


//获取登录二维码
export function pc_login_qr(data) {
  return require('post', '/api/hr24_elite/v1/pc_login_qr/', data)
}
//轮询是否允许登录
export function refresh_login(data) {
  return require('post', '/api/hr24_elite/v1/refresh_login/', data)
}
//公司 职位 详情  进简历雷达
export function resume_radar(data) {
  return require('post', '/api/hr24_elite/v1/resume_radar/', data)
}

//求职期望
export function basic_resume_create(data) {
  return require('post', '/api/hr24_elite/v1/basic_resume_create/', data)
}




// 获取企业视频列表
export function get_videolist(data) {
  return require('post', '/api/hr24_elite/v1/video_list/', data)
}

// 获取视频详情
export function get_videodetail(data) {
  return require('post', '/api/hr24_elite/v1/video_detail/', data)
}

// 获取零工视频列表
export function odd_video_list(data) {
  return require('post', '/api/hr24_elite/v1/odd_video_list/', data)
}
