import {
  getCompanyShow,
  getCompanyNewShow
} from '@/api/company/company'
import {
  work_time,
  educationBackground
} from './../../utils/searchtype'
import {
  getIsDown
} from '@/api/resume'

let module = {
  namespaced: true,
  state: {
    jd_data: [],
    hide: [],
    show: [],
    isDown: [],
    isBtn: true,
    newShow: []
  },
  mutations: {
    getJD(state, payload) {
      return getCompanyShow('jd').then(res => {
        if (res.code === 0) {
          state.isBtn = res.data.create_jd ? true : false
          state.jd_data = res.data.jd_data
          // console.log(res.data)
          if (state.jd_data && state.jd_data.length > 0) {
            state.jd_data.filter((item, index, self) => {

              item.place_name = item.jd_place_data.city_name + item.jd_place_data.district_name
              item.jd_exp_name = work_time[self[index].jd_exp_id].label,
                item.jd_edu_name =
                educationBackground[self[index].jd_edu_id].label

              return item
            })
          }
          console.log(state.jd_data)
          let a = [],
            b = []
          res.data.jd_data.filter(item => {
            if (item.jd_hide === 0) {
              a.push(item)
            } else {
              b.push(item)
            }
          })
          state.hide = b
          state.show = a
          // if (payload.succeed) {

          //   payload.succeed(res)
          // }
          // console.log(payload)
          // if (payload.success) {
          //   payload.success(res)
          // }
        }
      })
    },
    getResumeIsDown(state, payload) {
      return getIsDown().then(res => {
        if (res.code === 0) {
          state.isDown = res.data.is_download
          // if (payload.succeed && typeof payload.succeed == 'object') {
          //   payload.succeed(res)
          // }

        }
      })
    },
    getNewJD(state, payload) {
      return getCompanyNewShow({
        models_info: 'jd',
        jd_hide: 0,
        jd_status: 0
      }).then((res) => {
        if (res.code === 0) {
          if (sessionStorage.getItem('odd_job') == 1) {
            state.newShow = res.data.jd_data.filter(item => {
              return item.odd_status == 2
            })
          }else{
            state.newShow = res.data.jd_data;
          }
          // console.log(res.data.jd_data)
          console.log(state.newShow);
        }
      })
    }
  },
  actions: {
    getJD(conText, payload) {
      conText.commit('getJD', payload)

    },
    getResumeIsDown(conText, payload) {
      conText.commit('getResumeIsDown', payload)

    },
    getNewJD(conText, payload) {
      conText.commit('getNewJD', payload)

    },
  }
}
export default module