module.exports = [
    {
        id: "0",
        item: "首页",
        router: "/elite_index",
    },
    {
        id: "1",
        item: "职位",
        router: "/elite_position",
    },
    {
        id: "2",
        item: "企业",
        router: "/elite_company",
    },
    {
        id: "3",
        item: "企业职位视频",
        router: "/elite_video",
    },
    {
        id: "4",
        item: "简历",
        router: "/elite_resume",
    },
    {
        id: "5",
        item: "聊天",
        router: "/elite_chat",
    },
]