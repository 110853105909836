import axios from "axios"
import CryptoJS from 'crypto-js'
import router from "../router"
// axios.defaults.timeout = 7000

import {
  Loading,
  Message,
  Notification
} from 'element-ui'
var loadinginstace
var key = '&e-yw^a++%(&qy*z'
let _this = this
// 添加请求拦截器
// axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
//AES加密 
function encrypt(word, keyStr) {
  keyStr = keyStr ? keyStr : key;
  var key = CryptoJS.enc.Utf8.parse(keyStr);
  var srcs = CryptoJS.enc.Utf8.parse(word);
  var encrypted = CryptoJS.AES.encrypt(srcs, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  });
  return encrypted.toString();
}
//AES解密
function decrypt(word, keyStr) {
  keyStr = keyStr ? keyStr : key;
  var key = CryptoJS.enc.Utf8.parse(keyStr);
  var decrypt = CryptoJS.AES.decrypt(word, key, {
    mode: CryptoJS.mode.ECB,
    padding: CryptoJS.pad.Pkcs7
  });
  return CryptoJS.enc.Utf8.stringify(decrypt).toString();
}

// 请求拦截器
axios.interceptors.request.use(config => {
  // 在发送请求之前做些什么
  let token = sessionStorage.getItem('token')
  let elite_token = sessionStorage.getItem('elite_token')
  let is_elite = sessionStorage.getItem('is_elite')
  if (elite_token && is_elite == 1) {
    config.headers.token = elite_token

  } else if (token) {
    config.headers.token = token

  }



  return config;
}, (error) => {
  // 对请求错误做些什么

  // loadinginstace = Loading.service({ fullscreen: true })
  // Message.error({

  //   message: '加载超时'

  // })
  // loadinginstace.close()
  return Promise.reject(error);
});

// 添加响应拦截器
axios.interceptors.response.use((response) => {
  // 对响应数据做点什么
  // console.log(loadinginstace)
  // loadinginstace.close()
  // console.log(!response.data.code)

  // 多设备登录跳到登录页
  // 多人登陆以后，你这边请求接口做出响应时，后端那边会进行监控
  // 多人登录，后端也会做出成功200响应，所以在成功里面写，不在失败那写
  if (response.data.code == 110006) {
    Message.error({
      message: '该账号已在其他设备登录,请重新登录'
    })
    sessionStorage.clear()
    router.push('/login')
  }
  if(response.data.code == 100002){
    Message.warning({
      message: '登录时效已过，请重新登录'
    })
    sessionStorage.clear()
    router.push('/login')
  }

  return response.data;


}, function (error) {

  // 对响应错误做点什么
  // loadinginstace = Loading.service({ fullscreen: true })
  // let a = new Vue()
  // console.log(a)
  // console.log(Router.push('/'))


  Message.error({

    message: '加载超时,请刷新'

  })
  // loadinginstace.close()
  return Promise.reject(error);
});


function require(type, url, data, is_data = true) {
  let option = {}
  if (is_data) {
    option = new URLSearchParams()

    if (data instanceof Object) {

      for (var prop in data) {
        if (data[prop] instanceof Object) {
          option.append(prop, JSON.stringify(data[prop]))
        } else {
          option.append(prop, data[prop])

        }

      }
    }
  } else {
    option = data
  }

  // console.log('data', JSON.stringify(data))
  // let str = JSON.stringify(data)
  // data = encrypt(str)
  // console.log(url, data)
  var ajaxConfig
  if (type.toLowerCase() == 'get') {
    ajaxConfig = {
      method: type,
      url: url,
      params: option,
      proxy: true //支持代理解决跨域
    }
  } else {
    ajaxConfig = {
      method: type,
      url: url,
      data: option,
      proxy: true //支持代理解决跨域
    }
  }
  // console.log(axios(ajaxConfig))
  var promise = new Promise(function (resolve, ) {
    console.log(ajaxConfig);
    axios(ajaxConfig).then(res => {
      // console.log(res, '加密')
      let resData = ''
      if (typeof res == 'string') {
        resData = JSON.parse(decrypt(res, key))
      } else {
        resData = res
      }
      // console.log('请求地址', url, '传递数据', data, '解码', resData,)
      resolve(resData);
    })
  })
  return promise
}

export {
  require
}